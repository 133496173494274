/**
 * Static URLs for routing
 */
export enum UrlRoutingEnum  {
  userAdmin = 'user-administration',
  login = 'login',
  updatePassword = 'update-password',
  reports = 'incoherency-reports',
  loginLogs = 'login-logs',
  accountDeletionLogs = 'account-deletion-logs'
}

import { Observable } from 'rxjs/Observable';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.url.includes('saml2proxy') || request.url.includes('/cs-portal/v1') || request.url.includes('/bigquery/v1') ){
      request = request.clone({
          withCredentials: true
      });      
    }
  return next.handle(request);
  }
}
import { Component, enableProdMode, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export interface UserInfo {
  userId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  memberOf?: string;
  accessToken?: string;
  date?: Date;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'obe-spa';

  constructor(private router:Router) {

  }
  ngOnInit(): void {  
  }


}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiKeyPrdAuth: 'gl8ad21UGPVmSJ93Agk0QfLykAxZwtQe',
  apiKeyNonprodAuth: 'bAEJ6CUCv0LQuG0IxBzrQidOBiqqzxN2', 
  apiKey: '9QERTBQ0WinGxZEuzswALIhvEZr3NaI1',
  apiKeyBat: 'jGPqLs6IsJWopVAhIZjW3OFV1LTiE5uw',
  apiKeyCit: 'qN8tOMltXWuZplgyGoVT8QMTgdbq3R4H',
  apiKeyAcc: 'ZoXu8o2PDjaaiLU5ogGdssdiA7UIU22D',
  apiKeyNfr: 'A4Y14Jx2LaivAr2HjJmbTaUHiNNGaAyH',
  apiKeyRef: 'AM2qwxxuAIS0DSVW3MNVWihKCCOW7EFS',
  apiKeyPrd: 'L1CHQAR86Kq5RmFzNooAyGh58LFxArKa', 
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

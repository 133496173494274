import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UrlRoutingEnum } from './shared/enums/url-routing.enum';

const routes: Routes = [
  {
    path: UrlRoutingEnum.userAdmin,
    loadChildren:() => import('./user-admin/user-admin.module').then(m => m.UserAdminModule)
  },
  {
    path: UrlRoutingEnum.updatePassword,
    loadChildren:() => import('./reset-pwd/reset-pwd.module').then(m => m.ResetPwdModule)
  },
  {
    path: UrlRoutingEnum.reports,
    loadChildren:() => import('./reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: UrlRoutingEnum.loginLogs,
    loadChildren:() => import('./logs-explorer/logs-explorer.module').then(m => m.LogsExplorerModule)
  },
  {
    path: UrlRoutingEnum.accountDeletionLogs,
    loadChildren:() => import('./logs-explorer/logs-explorer.module').then(m => m.LogsExplorerModule)
  },
  {
    path: UrlRoutingEnum.login,
    loadChildren:() => import('./saml-authentication/saml-authentication.module').then(m => m.SamlAuthenticationModule)
  },
  {
    path: '**', redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
